<div class="relative rounded-md border-4" style="border-image: linear-gradient(to right, #9F5CC0, #2563EB) 1; background-color: #FFE9E2;">
    <div class="custom-scrollbar px-3  overflow-y-scroll">
        <div class="text-left text-xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 text-transparent bg-clip-text animate-spin-once mb-1 pt-2">
          TS Spotlight
        </div>
      <div class="">
        @for (content of contents.data()?.items; track content; let i = $index) {
          <p class="font-semibold">{{content.contentDate | date}}, {{content.title}}</p>
          <div [innerHTML]="content.text | safe:'html'"></div>
        }
      </div>
    </div>
  </div>