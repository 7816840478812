import { Component, OnInit, inject } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { NavBarComponent } from './pages/_nav-bar/nav-bar.component';
import { FooterComponent } from './pages/_footer/footer.component';
import { ResponsiveHelperComponent } from './shared/responsive-helper/responsive-helper.component';
import { ToasterComponent } from "./shared/toast/components/toaster/toaster.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { environment } from 'src/environments/environment';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  imports: [RouterOutlet, NavBarComponent, FooterComponent, ResponsiveHelperComponent, ToasterComponent, LoaderComponent, NgxDocViewerModule]
})
export class AppComponent implements OnInit {
  meta = inject(Meta);
  title = inject(Title);
  enviroment = environment
  ngOnInit(): void {
    if (environment.production) {
      const app = initializeApp(environment.firebaseConfig);
      const analytics = getAnalytics(app);
    }
    this.meta.addTags([
      { name: 'description', content: 'Free, Real-Time Alerts and Analyses of South Asian Trade and Investment Policy. Use TariffCompare to see how HS codes are treated across different countries.' },
      { property: 'og:title', content: 'Trade Sentinel - Real-Time Alerts and Analysis' },
      { property: 'og:description', content: 'Explore real-time alerts and in-depth analysis of trade and investment policies in South Asia. Enhance your business with TariffCompare.' },
      { property: 'og:type', content: 'website' },
    ]);
    this.title.setTitle('Trade Sentinel');
  }
}
