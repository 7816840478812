import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { EchartsDirective } from 'src/app/shared/echarts.directive';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'src/app/shared/base-component';
import { State } from 'src/app/shared/state';
import { CountryTabComponent } from "../_country-tab/country-tab.component";
import { CommingSoonComponent } from "../../../shared/comming-soon/comming-soon.component";
import { SafePipe } from "../../../shared/safe.pipe";
import { ApiService } from '@core/api/api.service';
import { GetContent, GetContentPublishedListResponse } from '@core/api/model';
import { Classifications } from '@core/api/extended/classifications';

@Component({
  selector: 'app-policy-trend',
  standalone: true,
  templateUrl: './policy-trend.component.html',
  styleUrls: ['./policy-trend.component.css'],
  animations: [
    trigger('slide', [
      state('0', style({ transform: 'translateX(0%)' })),
      state('1', style({ transform: 'translateX(-100%)' })),
      state('2', style({ transform: 'translateX(-200%)' })),
      transition('* => *', animate('500ms ease-in-out')),
    ]),
  ],
  imports: [EchartsDirective, CountryTabComponent, CommingSoonComponent, SafePipe]
})
export class PolicyTrendComponent extends BaseComponent implements OnInit {
  currentSlideIndex = 0;
  selectedCountry: string;

  contentList: GetContent[] | any[];
  domSanitizer = inject(DomSanitizer);
  contents = new State<GetContentPublishedListResponse>();

  apiService = inject(ApiService);

  ngOnInit(): void {
    this.onSelectedCountry('BANGLADESH');
  }

  onSelectedCountry(selectedCountry: string) {
    this.selectedCountry = selectedCountry;
    this.executeRequest<GetContentPublishedListResponse>({
      state: this.contents,
      asyncResource: this.apiService.getPublishedContentList(
        {
          countryId: this.selectedCountry,
          classificationId: Classifications.PolicyTrends,
        }
      ),

      onSuccess: response => {
        this.contentList = response.items.map((x: any) => {
          const data: any = {};
          data.text = this.domSanitizer.bypassSecurityTrustHtml(x.text);
          data.title = x.title;
          data.contentDate = x.contentDate;
          data.chartGroupId = x.chartGroupId;
          data.id = x.id;

          data.charts = x.charts.map(chart => {
            let value;
            if (chart.type != 'HTML' && chart.type != 'IMAGE') {
              var parsedOption = JSON.parse(chart.value);
              var formatterString = parsedOption?.tooltip?.formatter?.replace(/: any/g, '');
              let args = '';
              let myFunction;
              if (formatterString) {
                myFunction = new Function(args, formatterString);
                parsedOption.tooltip.formatter = myFunction;
              }

              var formatterString2 = parsedOption?.xAxis?.axisLabel?.formatter?.replace(/: any/g, '');
              let args2 = 'value';
              let myFunction2;
              if (formatterString2) {
                myFunction2 = new Function(args2, formatterString2);
                // Assign the formatter function directly
                parsedOption.xAxis.axisLabel.formatter = myFunction2;
              }

              value = {
                id: chart.id,
                name: chart.name,
                value: parsedOption,
                type: chart.type
              };
            } else {
              value = {
                id: chart.id,
                name: chart.name,
                value: chart.value,
                type: chart.type
              }
            }
            return value;
          });

          return data;
        });

        this.currentSlideIndex = 0;
      }
    });
  }

  nextSlide() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % (this.contentList[0].charts.length);
  }

  previousSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.contentList[0].charts.length) % (this.contentList[0].charts.length);
  }

}
