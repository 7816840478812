<div class="fixed top-0 left-0 right-0  z-10">
  <nav class="bg-gradient-to-br from-primary-500 to-primary-500 p-1 ">
    <div class="container mx-auto flex justify-between items-center">
      <div class="flex flex-row items-center space-x-2">
        <img class="mx-16 cursor-pointer" src="assets/images/logo.png" width="60" height="60" [routerLink]="['home']" />

      </div>

      <div class="lg:hidden">
        <button class="mr-3 text-background-A100 hover:text-background-A100" (click)="toggleMobileMenu()">
          <i class="fa fa-bars"></i>
        </button>
      </div>

      <div class="space-x-4 hidden lg:flex">
        <a class="text-background-50 hover:text-background-A100" routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;" [routerLink]="['home']">Home</a>
        <span class="relative group" [style.zIndex]="dropdownZIndex">
          <button class="text-background-50 hover:text-background-A100" [ngStyle]="isDropDownActive ? 
              { 'text-decoration-line': 'underline', 'text-decoration-thickness': '3px', 'text-underline-offset': '4px'} : 
              {}" (click)="toggleDropdown()">
            Countries <i class="fa fa-caret-down"></i>
          </button>
          @if (isDropdownOpen) {
          <div class="absolute left-0 mt-2 bg-background-A100 text-background-800">
            @for (country of countries; track country) {
            <a class="block px-4 w-52 py-2 cursor-pointer hover:bg-background-A100"
              routerLinkActive="underline text-background-50 decoration-solid" (click)="selectCountry(country)">
              {{ country.name }}
            </a>
            }
          </div>
          }
        </span>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['tariffcompare']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">TariffCompare</a>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['blog']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">Blogs</a>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['event']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">Events</a>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['team']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">Team</a>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['about']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">About Us</a>
        <a class="text-background-50 hover:text-background-A100" [routerLink]="['contact']"
          routerLinkActive="underline text-background-A100"
          style="text-decoration-thickness: 3px; text-underline-offset: 4px;">Contact Us</a>
      </div>
    </div>


    <div class="lg:hidden" [ngClass]="{'block': isMobileMenuOpen, 'hidden': !isMobileMenuOpen}"
      (click)="!isDropdownOpen?isMobileMenuOpen=false:isMobileMenuOpen=true">
      <a class="block p-4 text-background-A100 hover:text-background-A100" [routerLink]="['']">Home</a>
      <span class="block p-4 relative group" [style.zIndex]="dropdownZIndex">
        <button class="text-background-A100 hover:text-background-A100" (click)="toggleDropdown()">
          Countries <i class="fa fa-caret-down"></i>
        </button>
        @if (isDropdownOpen) {
        <div (click)="toggleDropdown()" class="fixed inset-0 w-full cursor-default"></div>
        }
        @if (isDropdownOpen) {
        <div class="absolute left-0 mt-2 bg-background-A100 text-background-800">
          @for (country of countries; track country) {
          <a class="block px-4 w-52 py-2 cursor-pointer hover:background-A100"
            (click)="selectCountry(country)">{{country.name}}</a>
          }
        </div>
        }
      </span>
      <a class="block p-4 text-background-A100 hover:background-A100" [routerLink]="['tariffcompare']"
        routerLinkActive="underline text-background-50">TariffCompare</a>
      <a class="block p-4 text-background-A100 hover:background-A100" [routerLink]="['blog']">Blogs</a>
      <a class="block p-4 text-background-A100 hover:background-A100" [routerLink]="['event']">Events</a>
      <a class="block p-4 text-background-A100 hover:background-A100" [routerLink]="['team']"
        routerLinkActive="underline text-background-50">Team</a>
      <a class="block p-4 text-background-A100 hover:text-background-A100" [routerLink]="['about']">About Us</a>

      <a class="block p-4 text-background-A100 hover:background-A100" [routerLink]="['contact']">Contact Us</a>
    </div>
  </nav>

  @if(!enviroment.production) {
  <span class="absolute top-10 -right-10 transform rotate-45  bg-warning-500 text-white p-1 px-16 rounded">
    Development
  </span>
  }
</div>