import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { GetCountryListResponse } from '@core/api/model';
import { BaseComponent } from 'src/app/shared/base-component';
import { State } from 'src/app/shared/state';

@Component({
  selector: 'app-country-tab',
  standalone: true,
  imports: [],
  templateUrl: './country-tab.component.html',
  styleUrl: './country-tab.component.css'
})
export class CountryTabComponent extends BaseComponent implements OnInit {
  activeTab: string = 'BANGLADESH';
  @Output() selectedCountry = new EventEmitter<string>();

  apiService = inject(ApiService);
  countries = new State<GetCountryListResponse>();

  ngOnInit(): void {
    this.executeRequest<GetCountryListResponse>({
      state: this.countries,
      asyncResource: this.apiService.getCountryList(
        {
          pageNumber: 1,
          pageSize: 10,
          sortingDirection: 'asc',
          orderByPropertyName: 'name',
        }
      ),
    });
  }

  onCountrySelect(tab: string) {
    this.activeTab = tab;
    this.selectedCountry.emit(tab);
  }

}
