import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'about',
        loadComponent: () =>
            import('./pages/about-us/about-us.component').then((x) => x.AboutUsComponent),
    },
    {
        path: 'contact',
        loadComponent: () =>
            import('./pages/contact-us/contact-us.component').then((x) => x.ContactUsComponent),
    },
    {
        path: 'blog',
        loadComponent: () =>
            import('./pages/blog/blog.component').then((x) => x.BlogComponent),
    },
    {
        path: 'event',
        loadComponent: () =>
            import('./pages/event/event.component').then((x) => x.EventComponent),
    },
    {
        path: 'subscribe',
        loadComponent: () =>
            import('./pages/subscribe/subscribe.component').then((x) => x.SubscribeComponent),
    },
    {
        path: 'team',
        loadComponent: () =>
            import('./pages/team/team.component').then((x) => x.TeamComponent),
    },
    {
        path: 'country/:id',
        loadComponent: () =>
            import('./pages/country/country.component').then((x) => x.CountryComponent),

    },
    {
        path: 'content/:id',
        loadComponent: () =>
            import('./pages/content/content.component').then((x) => x.ContentComponent),
    },
    {
        path: 'notification-information/:id',
        loadComponent: () =>
            import('./pages/country/notification-information/notification-information.component').then((x) => x.NotificationInformationComponent),
    },
    {
        path: 'summary/:countryId/:id',
        loadComponent: () =>
            import('./pages/country/summary/summary.component').then((x) => x.SummaryComponent),
    },
    {
        path: 'tariffcompare',
        loadComponent: () =>
            import('./pages/tariffcompare/tariffcompare.component').then((x) => x.TariffCompareComponent),
    },
    {
        path: 'subscribe-verification',
        loadComponent: () =>
            import('./pages/subscribe-verification/subscribe-verification.component').then((x) => x.SubscribeVerificationComponent),
    },
    {
        path: 'unsubscribe',
        loadComponent: () =>
            import('./pages/unsubscribe/unsubscribe.component').then((x) => x.UnsubscribeComponent),
    },
    {
        path: 'comming-soon',
        loadComponent: () =>
            import('./shared/comming-soon/comming-soon.component').then((x) => x.CommingSoonComponent),
    },
];
