import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { Classifications } from '@core/api/extended/classifications';
import { GetContentPublishedListResponse } from '@core/api/model';
import { BaseComponent } from '@shared/base-component';
import { SafePipe } from '@shared/safe.pipe';
import { State } from '@shared/state';

@Component({
  selector: 'app-spotlight',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SafePipe,
    DatePipe
  ],
  templateUrl: './spotlight.component.html',
  styleUrl: './spotlight.component.css'
})
export class SpotlightComponent   extends BaseComponent implements OnInit {
  contents = new State<GetContentPublishedListResponse>();
  apiService = inject(ApiService);
  
  ngOnInit(): void {
    this.executeRequest<GetContentPublishedListResponse>({
      state: this.contents,
      asyncResource: this.apiService.getPublishedContentList(
        {
          pageNumber: 1,
          pageSize: 1,
          sortingDirection: 'desc',
          orderByPropertyName: 'contentDate',
          classificationId: Classifications.SpotLight,
        }
      ),
    });
  }

}
