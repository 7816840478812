<div>
  @if (contents.data()?.items.length != 0) {

  <div class="flex justify-between items-end ">
    <h4 class="text-xl font-normal text-primary-500 mx-3 my-2">Blogs</h4>
    <button class="bg-primary-500 hover:bg-primary-700 w-28 my-2 mx-2 text-background-A100 font-bold px-4 rounded float-right"
      (click)="view()">More</button>
  </div>

  <div class="mx-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-16">
    @for (content of contents.data()?.items; track content; let i = $index) {
    <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">

      <div class="ql-editor h-96 overflow-scroll custom-scrollbar">
        <p class="font-semibold">{{content.contentDate | date}}</p>
        <p class="font-semibold">{{content.title}}</p>
        <div [innerHTML]="content.text | safe:'html'"></div>
      </div>
    </div>
    }
  </div>
  }
</div>