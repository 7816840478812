import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-responsive-helper',
  standalone: true,
  imports: [],
  templateUrl: './responsive-helper.component.html',
  styleUrls: ['./responsive-helper.component.css']
})
export class ResponsiveHelperComponent {
  public env: any = environment;

  constructor() {}

  ngOnInit(): void {}
}
