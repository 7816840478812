<section class="relative text-center">
  <img src="assets/images/cov.gif" class="hidden lg:block w-full max-h-64 h-auto md:h-40 mt-10" />
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 text-background-A100 text-4xl md:text-7xl">
    <p class="text-background-A100 text-4xl md:text-7xl hidden md:block mt-12">Trade Sentinel</p>
    <p class="text-background-A100 text-lg md:text-xl mt-0 md:mt-0 hidden md:block">Free, Real-Time Alerts and Analyses of South Asian Trade and Investment Policy</p>
  </div>
</section>

<section class="mt-28 md:mt-0">
  <app-flash-news></app-flash-news>
</section>

<div class="px-12 py-5">

<section class="m-3 ">
<app-spotlight></app-spotlight>
</section>

<section class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-7 gap-2 mt-1 md:mt-24 lg:mt-5">
  <div class="bg-white rounded-md col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-3 ml-3 px-2 py-2">
    <app-notification></app-notification>
  </div>
  <!-- <div class="col-span-1 sm:col-span-2 md:col-span-1">
    <app-spotlight></app-spotlight>
  </div> -->
  <div class="bg-white rounded-md col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-4 mr-3 px-2">
    <app-policy-trend></app-policy-trend>
  </div>
</section>

<section class="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-around md:h-full lg:h-full mx-3 my-5 gap-2">
  
  <div class="bg-white rounded-md py-2">
    <app-analysis></app-analysis>
  </div>
  <div class="bg-white rounded-md mb-2 sm:mb-0 md:mb-0 lg:mb-0">
    <app-tariff-compare></app-tariff-compare>
  </div>
</section>
</div>