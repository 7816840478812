import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'src/app/shared/base-component';
import { State } from 'src/app/shared/state';
import { SafePipe } from "../../../shared/safe.pipe";
import { ApiService } from '@core/api/api.service';
import { GetContentPublishedListResponse } from '@core/api/model';
import { Classifications } from '@core/api/extended/classifications';
@Component({
  selector: 'app-analysis',
  standalone: true,
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css'],
  animations: [
    trigger('slide', [
      state('0', style({ transform: 'translateX(0%)' })),
      state('1', style({ transform: 'translateX(-100%)' })),
      state('2', style({ transform: 'translateX(-200%)' })),
      transition('* => *', animate('500ms ease-in-out')),
    ]),
  ],
  imports: [CommonModule, SafePipe]
})
export class AnalysisComponent extends BaseComponent implements OnInit {
  currentSlideIndex = 0;

  apiService = inject(ApiService);

  contents = new State<GetContentPublishedListResponse>();

  ngOnInit(): void {
    this.executeRequest<GetContentPublishedListResponse>({
      state: this.contents,
      asyncResource: this.apiService.getPublishedContentList(
        {
          pageNumber: 1,
          pageSize: 2,
          sortingDirection: 'desc',
          orderByPropertyName: 'contentDate',
          classificationId: Classifications.Blog,
        }
      ),
    });
  }


  nextSlide() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % (this.contents.data().items.length);
  }

  previousSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.contents.data().items.length) % this.contents.data().items.length;
  }
  view() {
    this.router.navigate([`blog`])
  }
}
