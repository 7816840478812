
import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-tariff-compare',
  standalone: true,
  imports: [
    RouterModule,
    RouterOutlet
],
  templateUrl: './tariff-compare.component.html',
  styleUrl: './tariff-compare.component.css'
})
export class TariffCompareComponent {

}
