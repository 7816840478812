    <section class="py-10 bg-background-A100 sm:py-6 lg:py-8 h-full">
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
        <div class="grid items-center max-w-6xl grid-cols-1 mx-auto md:grid-cols-2 md:gap-x-16 h-full">
          <div class="md:order-2 h-full">
            <div class="max-w-md mx-auto h-full">
              <div class="aspect-w-4 aspect-h-3 h-full">
                <img class="object-cover w-full h-full" src="assets/images/tariff.png" alt="" />
              </div>
            </div>
          </div>

          <div class="px-6 py-8 text-center md:text-left md:p-0 md:order-1 h-full">
            <h3 class="mt-6 text-2xl font-bold text-gray-900 sm:text-4xl xl:text-2xl font-poppins">
              <span class="text-primary-800">TariffCompare</span>
            </h3>
            <p class="mt-8 text-lg font-normal ">
              A tool to compare Current (2023-24) Import Tariff rates at more than 5000 individual tariff line levels
              - by a comparison table or a visual. It also displays the latest trade related notifications issued by
              the government for the relevant product picked, if available.
            </p>

            <button [routerLink]="['/tariffcompare']" title=""
              class="inline-flex items-center px-4 py-2 mt-8 font-semibold text-sm lg:text-base text-primary-500 border border-primary-500 rounded-full hover:bg-primary-500 hover:text-background-A100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition-colors duration-300 ease-in-out group font-poppins animate-shake">
              Try TariffCompare
              <svg class="w-5 h-5 ml-2 transition-transform duration-300 ease-in-out group-hover:translate-x-1"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
