<section class="">

  <div class="flex-col">
    <h4 class="text-xl font-normal  text-primary-500 mx-3 my-2">Latest Trade Notifications</h4>

    <div class=" grid grid-cols-2 justify-center gap-4">
      <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-200 ">
        <span class="text-primary-500 font-semibold flex justify-center">Bangladesh</span>
        @for (content of bangladeshContentList; track content; let i = $index) {
          <div class="relative ql-editor h-72 overflow-scroll custom-scrollbar">
            <p class="font-semibold">{{content.contentDate | date}}</p>
            <div class="" [innerHTML]="content.text | safe:'html'"></div>
          </div>
          <button class="read-more-btn text-blue-500 hover:bg-primary-700 hover:text-white cursor-pointer" (click)="readMore('BANGLADESH')">Read more</button>

        }
      </div>
      

      <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-200">
        <span class="text-primary-500 font-semibold flex justify-center ">India</span>
        @for (content of indiaContentList; track content; let i = $index) {
        <div class="ql-editor  h-72 overflow-scroll custom-scrollbar">
          <p class="font-semibold ">{{content.contentDate | date}}</p>
          <div class="" [innerHTML]="content.text | safe:'html'"></div>

        </div>

        }
        <button class="read-more-btn text-blue-500 hover:bg-primary-700 hover:text-white cursor-pointer" (click)="readMore('INDIA')">Read more</button>

        
      </div>
      @if(nepalContentList.length > 0) {
      <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-200 " >
        <span class="text-primary-500 font-semibold flex justify-center">Nepal</span>
        @for (content of nepalContentList; track content; let i = $index) {
        <div class="ql-editor  h-72 overflow-scroll custom-scrollbar">
          <p class="font-semibold ">{{content.contentDate | date}}</p>
          <div class="" [innerHTML]="content.text | safe:'html'"></div>

        </div>
        }
        <button class="read-more-btn text-blue-500 hover:bg-primary-700 hover:text-white cursor-pointer" (click)="readMore('NEPAL')">Read more</button>

      </div>
    }

      <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-200 ">
        <span class="text-primary-500 font-semibold flex justify-center">Pakistan</span>
        @for (content of pakistanContentList; track content; let i = $index) {
        <div class="ql-editor  h-72 overflow-scroll custom-scrollbar">
          <p class="font-semibold ">{{content.contentDate | date}}</p>
          <div class="" [innerHTML]="content.text | safe:'html'"></div>

        </div>
        }
        <button class="read-more-btn text-blue-500 hover:bg-primary-700 hover:text-white cursor-pointer" (click)="readMore('PAKISTAN')">Read more</button>

      </div>

        <div class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-200 ">
          <span class="text-primary-500 font-semibold flex justify-center">Sri Lanka</span>
          @for (content of sriLankaContentList; track content; let i = $index) {
          <div class="ql-editor  h-72 overflow-scroll custom-scrollbar">
            <p class="font-semibold">{{content.contentDate | date}}</p>
            <div class="" [innerHTML]="content.text | safe:'html'"></div>

          </div>
          }
          <button class="read-more-btn text-blue-500 hover:bg-primary-700 hover:text-white cursor-pointer" (click)="readMore('SRILANKA')">Read more</button>

        </div>

    </div>

 

  </div>
</section>