<footer class="bg-primary-500 text-background-A100 body-font">
    <div class="container px-5 py-12 mx-auto flex flex-col md:flex-row items-center md:flex-nowrap">
        <div class="w-64 md:w-1/4 flex-shrink-0 mx-auto text-center md:text-left">
            <a class="flex title-font font-medium items-center justify-center md:justify-start">
                <img src="assets/images/logo.png" class="w-8 h-8 text-background-A100" alt="TradeSentinel Logo">
                <span class="ml-3 text-xl text-background-A100">Trade Sentinel</span>
            </a>
            <p class="mt-2 text-sm text-background-50">Free, Real-Time Alerts and Analyses
                of South Asian Trade and Investment Policy </p>
            <p> <a class="mt-2 text-md underline text-background-50 cursor-pointer" (click)="navToSubscribe()">Subscribe
                    Notification Alerts </a></p>
            <a class="mt-2 text-md underline text-background-50 cursor-pointer" (click)="navToPrivacyPolicy()">Privacy
                Policy </a>
        </div>
        <div class="flex-grow flex flex-wrap md:pl-12 md:mt-0 mt-4 md:text-left text-center">
            <div class="lg:w-2/3 md:w-1/2 w-full">
                <h2 class="title-font font-medium text-background-A100 tracking-widest text-sm mb-2">SUPPORTED BY</h2>
                <nav class="list-none">
                    <ul>
                        <li><a href="https://www.kas.de/en/web/japan" target="_blank"
                                class="text-background-A100 underline hover:text-background-100 w-full">Federal Republic
                                of
                                Germany through Konrad Adenauer Stiftung, Tokyo, Japan</a></li>
                        <li><a href="https://csep.org/" target="_blank"
                                class="text-background-A100 underline hover:text-background-100">Centre for Social and
                                Economic Progress, New Delhi, India</a></li>
                    </ul>
                </nav>
            </div>
            <div class="lg:w-1/3 md:w-1/2 w-full px-4">
                <h2 class="title-font font-medium text-background-A100 tracking-widest text-sm mb-2">Write to us</h2>
                <nav class="list-none">
                    <ul>
                        <li><a href="mailto:prakhar_chaudhary@tradesentinel.org"
                                class="text-background-A100">prakhar_chaudhary&#64;tradesentinel.org</a></li>
                    </ul>
                    <div class="flex justify-center sm:justify-start  mt-5 ">

                        <p class="text-background-A100 text-md mr-1">Follow Trade Sentinel on:</p>
                        <!-- LinkedIn Icon -->
                        <a href="https://www.linkedin.com/company/trade-sentinel/posts/?feedView=all" target="_blank"
                            class="ml-1 text-background-900">
                            <img src="assets/icons/linkedin.svg" class="w-8 h-6" alt="LinkedIn">
                        </a>
                        <!-- Twitter (X) Icon -->
                        <a href="https://x.com/TradeSentinel_" target="_blank"
                            class="ml-3 text-background-900 bg-white">
                            <img src="assets/icons/twitterx.svg" class="w-6 h-6" alt="Twitter (X)">
                        </a>

                        <a href="https://www.youtube.com/@PrakharChaudhary_TradeSentinel" target="_blank"
                            class="ml-3 text-background-900">
                            <img src="assets/icons/youtube.svg" class="w-6 h-6" alt="Twitter (X)">
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <div class="bg-background-50">
        <div class="container mx-auto py-2 px-5 flex flex-wrap flex-col sm:flex-row justify-between">
            <p class="text-background-900 text-sm text-center sm:text-left">Trade Sentinel All Rights Reserved © 2024
            </p>
            <div class="flex justify-center sm:justify-start">
                <p class="text-background-900 text-sm mr-4">Version: {{enviroment.version}}</p>
            </div>
        </div>
    </div>
</footer>