<section class="flex mx-auto flex-wrap mb-2 justify-center">
    @for (country of countries?.data()?.items; track country) {
    <span>
        <a (click)="onCountrySelect(country.id)"
            [class]="activeTab === country.id ? 'border-primary-500 text-primary-500' : 'bg-background-100'"
            class="px-3 py-2 w-full sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium cursor-pointer no-underline hover:underline inline-flex items-center leading-none tracking-wider rounded-t"
            style="box-sizing: border-box;">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="w-4 h-4 sm:w-5 sm:h-5 mr-2" viewBox="0 0 24 24">
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>{{country.name}}
        </a>
    </span>
    }
</section>