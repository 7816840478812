<!-- <div class="bg-white md:m-5 xl:m-5 pattern h-full">
  <h4 class="text-xl font-normal text-primary-500 mb-1">Policy Trends</h4>

  <div class="bg-background-A100 shadow-lg rounded-lg p-4 text-background-600 body-font">
    <app-country-tab (selectedCountry)="onSelectedCountry($event)"></app-country-tab>

    @for (content of contentList; track content) {
    <div class="mt-2">
      <div class="flex flex-col items-start justify-start">
        <div>
          <div class="mt-2 ml-5 mb-5 ql-editor">
            <div [innerHTML]="content.text"></div>
          </div>
        </div>
      </div>
      <div class="carousel bg-background-A100 overflow-hidden relative">
        <div class="carousel-inner flex" [@slide]="currentSlideIndex">
          @for (chart of content.charts; track chart; let i = $index) {
          <div class="w-full flex-shrink-0"
            [ngStyle]="{'transform': 'translateX(' + (i - currentSlideIndex) * 100 + '%)'}">
            @if (chart.type==='HTML') {
            <div class="flex flex-row justify-center w-full">
              <iframe [src]="chart.value | safe:'url'" title="Iframe Example"></iframe>
            </div>
            } @else if (chart.type==='IMAGE') {
            <div class="flex flex-row justify-center w-full h-full">
              <img [src]="chart.value"  />
            </div>
            }
            @else {
            <div class="mr-14 ml-14 w-full h-full">
              <div echarts [options]="chart.value" class="w-full h-full"></div>
            </div>
            }
          </div>
          }
        </div>
        <button [hidden]="content?.charts?.length<2"
          class="carousel-button absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-blue-500 text-white ml-4"
          (click)="previousSlide()">
          < </button>
            <button [hidden]="content?.charts?.length<2"
              class="carousel-button absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-blue-500 text-white mr-4"
              (click)="nextSlide()">
              >
            </button>
      </div>
    </div>
    } @empty {
    <app-comming-soon></app-comming-soon>
    }
  </div>
</div> -->

<div class="bg-white rounded-md my-3">
  <h4 class="text-xl font-normal ml-4 text-primary-500 mb-1">Policy Trends</h4>

  <app-country-tab (selectedCountry)="onSelectedCountry($event)"></app-country-tab>
  @for (content of contentList; track content) {

  <div class="ql-editor">
    <div [innerHTML]="content.text"></div>
  </div>

  @for (chart of content.charts; track chart; let i = $index) {
  <div class="">
    @if (chart.type==='HTML') {
      <div class="flex flex-col ">
      <iframe class="h-128" [src]="chart.value | safe:'url'"></iframe>
    </div>
    } @else if (chart.type==='IMAGE') {
    <div class="">
      <img [src]="chart.value" />
    </div>
    }
    @else {
    <div class="">
      <div echarts [options]="chart.value" class=""></div>
    </div>
    }
  </div>
  }
  }
</div>