import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { Subject, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '@core/api/api.service';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
  ],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit, OnDestroy {
  private readonly unSubscribe$ = new Subject<void>();
  isMobileMenuOpen = false;
  isDropdownOpen = false;
  dropdownZIndex = 1000;
  countries: any[];
  activeUrl: string;
  isDropDownActive: boolean;
  enviroment = environment


  constructor(private router: Router, private apiService: ApiService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Set activeUrl to the current route
      this.activeUrl = event.urlAfterRedirects;
      // Update isActive status for each country
      if (this.countries)
        this.countries.forEach(country => {
          this.isDropDownActive = this.activeUrl.includes('country'); // Assuming 'route' is a property with the country's route
        });
    });
  }

  ngOnInit(): void {
    this.apiService.getCountryList(
      {
        sortingDirection: 'asc',
        orderByPropertyName: 'priority',
      }
    ).subscribe({
      next: (value) => {
        this.countries = value.items;
      },
      complete: () => {
      },
      error(err) {
        console.log(err);
      },
    });
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.dropdownZIndex = this.isDropdownOpen ? 2000 : 1000;
  }

  trackByFunction(index, item) {
    return item.id; // or whatever unique identifier you have for your items
  }

  navToHome() {
    this.router.navigate(['']);
  }

  selectCountry(country: any) {
    this.isDropdownOpen = false;
    this.router.navigateByUrl(`country/${country.id}`, { onSameUrlNavigation: 'reload', replaceUrl: true });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
