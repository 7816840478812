import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Classifications } from '@core/api/extended/classifications';
import { BaseComponent } from 'src/app/shared/base-component';
import { State } from 'src/app/shared/state';
import { SafePipe } from "../../../shared/safe.pipe";
import { CountryTabComponent } from '../_country-tab/country-tab.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from '@core/api/api.service';
import { GetContentPublishedListResponse, GetCountryListResponse } from '@core/api/model';
import { CommingSoonComponent } from "../../../shared/comming-soon/comming-soon.component";

@Component({
    selector: 'app-notification',
    standalone: true,
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css'],
    animations: [
        trigger('slide', [
            state('0', style({ transform: 'translateX(0%)' })),
            state('1', style({ transform: 'translateX(-100%)' })),
            state('2', style({ transform: 'translateX(-200%)' })),
            transition('* => *', animate('500ms ease-in-out')),
        ]),
    ],
    imports: [CommonModule, SafePipe, CountryTabComponent, CommingSoonComponent]
})
export class NotificationComponent extends BaseComponent implements OnInit {
  selectedCountry: string;
  currentSlideIndex = 0;

  apiService = inject(ApiService);

  countries = new State<GetCountryListResponse>();
  contents = new State<GetContentPublishedListResponse>();
  contentList: any[] = [];
  bangladeshContentList: any[] = [];
  indiaContentList: any[] = [];
  nepalContentList: any[] = [];
  pakistanContentList: any[] = [];
  sriLankaContentList: any[] = [];

  ngOnInit(): void {
    this.onSelectedCountry('BANGLADESH');
    this.onSelectedCountry('INDIA');
    this.onSelectedCountry('NEPAL');
    this.onSelectedCountry('PAKISTAN');
    this.onSelectedCountry('SRILANKA');
  }

  onSelectedCountry(selectedCountry: string) {
    this.currentSlideIndex = 0;
    this.selectedCountry = selectedCountry;
    this.executeRequest<GetContentPublishedListResponse>({
      state: this.contents,
      asyncResource: this.apiService.getPublishedContentList({
        pageNumber: 1,
        pageSize: 1,
        sortingDirection: 'desc',
        orderByPropertyName: 'contentDate',
        countryId: this.selectedCountry,
        classificationId: Classifications.Notifications,
      }),

      onSuccess: (response) => {
        if(selectedCountry === 'BANGLADESH') {
        this.bangladeshContentList = response.items;
        }
        if(selectedCountry === 'INDIA') {
          this.indiaContentList = response.items;
        }
        if(selectedCountry === 'NEPAL') {
          this.nepalContentList = response.items;
        }
        if(selectedCountry === 'PAKISTAN') {
          this.pakistanContentList = response.items;
        }
        if(selectedCountry === 'SRILANKA') {
          this.sriLankaContentList = response.items;
        }

      },
    });
  }

  view(selectedCountry:string) {
    this.router.navigate([`notification-information/${selectedCountry}`])
  }

  nextSlide() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % (this.contents.data().items.length);
  }

  previousSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.contents.data().items.length) % this.contents.data().items.length;
  }

  readMore(selectedCountry:string) {
    this.router.navigate([`notification-information/${selectedCountry}`])
  }

}
