import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Classifications } from '@core/api/extended/classifications';
import { GetContentPublishedListResponse } from '@core/api/model';
import { BaseComponent } from '@shared/base-component';
import { State } from '@shared/state';
import { SafePipe } from "../../../shared/safe.pipe";

@Component({
  selector: 'app-flash-news',
  standalone: true,
  imports: [SafePipe],
  templateUrl: './flash-news.component.html',
  styleUrl: './flash-news.component.css',
  host: { ngSkipHydration: 'true' },
})
export class FlashNewsComponent extends BaseComponent implements OnInit {
  contents = new State<GetContentPublishedListResponse>();
  apiService = inject(ApiService);


  ngOnInit(): void {
    this.executeRequest<GetContentPublishedListResponse>({
      state: this.contents,
      asyncResource: this.apiService.getPublishedContentList(
        {
          pageNumber: 1,
          pageSize: 5,
          sortingDirection: 'desc',
          orderByPropertyName: 'contentDate',
          classificationId: Classifications.FlashNews,
        }
      ),
    });
  }


  navTo(isExternal:boolean, externalLink?: string, id?:string) {
    console.log('navTo', isExternal, externalLink, id);
    if(isExternal){
      window.open(externalLink, '_blank');
    }else{
      this.router.navigate([`/content/${id}`]);
    }
  }
}
