<app-toaster></app-toaster>
<app-loader></app-loader>

<div class="flex flex-col min-h-screen bg-background-50">
        <main class="flex-grow">
                <app-nav-bar></app-nav-bar>
                <div class="relative mt-6">
                        <router-outlet></router-outlet>
                </div>
        </main>
        <app-footer></app-footer>
        <app-responsive-helper></app-responsive-helper>
</div>
