<div class="bg-black h-6 w-full">
    <marquee direction="left">
        <div>
            <ng-container class="text-sm font-bold">
                @for (content of contents.data()?.items; track content; let i = $index) {
                <p class="mr-14 inline text-xs sm:text-sm text-yellow-600 cursor-pointer hover:underline" (click)="navTo(content.isExternal, content.externalLink, content.id)">
                    <!-- <div [innerHTML]="content.text | safe:'html'" (click)="navTo(content.isExternal, content.externalLink, content.id)"></div> -->
<span>{{content.title}}</span>
                </p>
                }
            </ng-container>
        </div>
    </marquee>
</div>

