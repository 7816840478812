import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseComponent } from '@shared/base-component';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends BaseComponent {
  enviroment = environment

  navToSubscribe() {
    this.router.navigate([`subscribe`]);
  }

  navToPrivacyPolicy() {
    this.router.navigate([`privacy-policy`]);
  }
}
