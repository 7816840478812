import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';

import { TabComponent } from '../country/tab/tab.component';
import { NotificationComponent } from './notification/notification.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { PolicyTrendComponent } from './policy-trend/policy-trend.component';
import { EchartsDirective } from 'src/app/shared/echarts.directive';
import { RouterModule } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { DatePipe, NgClass } from '@angular/common';
import { State } from '@shared/state';
import { GetContentPublishedListResponse } from '@core/api/model';
import { BaseComponent } from '@shared/base-component';
import { ApiService } from '@core/api/api.service';
import { SafePipe } from '@shared/safe.pipe';
import { SpotlightComponent } from "./spotlight/spotlight.component";
import {TariffCompareComponent } from "./tariff-compare/tariff-compare.component";
import { EqualHeightDirective } from '@shared/equal-height.directive';
import { FlashNewsComponent } from "./flash-news/flash-news.component";
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    EchartsDirective,
    TabComponent,
    NotificationComponent,
    AnalysisComponent,
    PolicyTrendComponent,
    TariffCompareComponent,
    RouterModule,
    NgClass,
    SafePipe,
    DatePipe,
    SpotlightComponent,
    EqualHeightDirective,
    FlashNewsComponent
],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent  extends BaseComponent implements OnInit {
  meta = inject(Meta);
  title = inject(Title);
  platformId = inject(PLATFORM_ID);
  isDisableScroll: boolean = false;

  activeSection: string | null = null;
  contents = new State<GetContentPublishedListResponse>();
  apiService = inject(ApiService);
  ngOnInit(): void {
    this.meta.addTags([
      { name: 'description', content: 'Free, Real-Time Alerts and Analyses of South Asian Trade and Investment Policy. Use TariffCompare to see how HS codes are treated across different countries.' },
      { property: 'og:title', content: 'Trade Sentinel - Real-Time Alerts and Analysis' },
      { property: 'og:description', content: 'Explore real-time alerts and in-depth analysis of trade and investment policies in South Asia. Enhance your business with TariffCompare.' },
      { property: 'og:type', content: 'website' },
    ]);
    this.title.setTitle('Trade Sentinel');
  }

}

