import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';

@Directive({
standalone: true,
  selector: '[appEqualHeight]'
})
export class EqualHeightDirective implements AfterViewInit {

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    @HostListener('window:resize')
    onResize() {
      this.setEqualHeight();
    }
  
    ngAfterViewInit() {
      this.setEqualHeight();
    }
  
    private setEqualHeight() {
      const parent = this.el.nativeElement;
      const children = parent.querySelectorAll('.same-height');
  
      let maxHeight = 0;
  
      // Reset height to auto to get natural heights
      children.forEach((child: HTMLElement) => {
        this.renderer.setStyle(child, 'height', 'auto');
      });
  
      // Find the maximum height
      children.forEach((child: HTMLElement) => {
        if (child.offsetHeight > maxHeight) {
          maxHeight = child.offsetHeight;
        }
      });
  
      // Set all elements to the maximum height
      children.forEach((child: HTMLElement) => {
        this.renderer.setStyle(child, 'height', `${maxHeight}px`);
      });
    }
}